exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-garagedoorrepair-l-1-js": () => import("./../../../src/pages/garagedoorrepair-l1.js" /* webpackChunkName: "component---src-pages-garagedoorrepair-l-1-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-opener-repair-js": () => import("./../../../src/pages/opener-repair.js" /* webpackChunkName: "component---src-pages-opener-repair-js" */),
  "component---src-pages-opener-repair-l-1-js": () => import("./../../../src/pages/opener-repair-l1.js" /* webpackChunkName: "component---src-pages-opener-repair-l-1-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-spring-repair-js": () => import("./../../../src/pages/spring-repair.js" /* webpackChunkName: "component---src-pages-spring-repair-js" */),
  "component---src-pages-spring-repair-l-1-js": () => import("./../../../src/pages/spring-repair-l1.js" /* webpackChunkName: "component---src-pages-spring-repair-l-1-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

